<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div class="footer-copyright">
              <b>&copy; {{ year }} PortaViridi, All Rights Reserved.</b>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div>
              <a href="https://www.kitomer.com/terms-of-use" target="_blank"
                >Website Terms of Use</a
              >
              |
              <a href="https://www.kitomer.com/privacy-policy" target="_blank"
                >Privacy Policy</a
              >
              |
              <a href="https://www.kitomer.com/compliance" target="_blank"
                >Compliance</a
              >
            </div>
          </div>
          <div class="level-item">
            <span class="tag">v{{ version }}</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import dayjs from "dayjs";
import { version } from "../../package.json";

export default {
  name: "FooterBar",
  computed: {
    year() {
      return dayjs().year();
    },
    version() {
      return version || 0;
    }
  }
};
</script>
<style lang="scss" scoped>
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .level-left + .level-right {
    margin-top: 0.25rem;
  }
}
</style>
