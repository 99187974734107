<template>
  <div>
    <section v-if="reservation">
      <div class="container px-4">
        <div v-if="!reservation.paymentCompleted">
          <p class="mb-3">
            You have an outstanding payment request.
          </p>
          <router-link
            class="button is-large is-fullwidth is-success"
            :to="{ name: 'Payment', params: { reservationId: reservation.id } }"
            >Purchase</router-link
          >
        </div>
        <div class="has-text-centered mt-5">
          <qrcode-vue size="200" :value="reservation.id"></qrcode-vue>
        </div>
        <div class="mb-2 has-text-centered">
          <strong class="is-size-3">{{ reservation.code }}</strong>
        </div>
        <div>
          <p class="mb-4">
            Display this information at our Gatehouse upon first arrival.
          </p>
          <p>This is a one-time access code.</p>
        </div>
      </div>
    </section>
    <section v-else>
      <div class="container px-4">
        <div>
          <h3 class="mb-4 is-size-3">
            Uh oh!
          </h3>
          <p>This does not appear to be a valid URL.</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import QrcodeVue from "qrcode.vue";

export default {
  components: { QrcodeVue },
  methods: {
    ...mapActions({
      registerGuest: "guest/registerGuest",
      confirmReservation: "reservation/confirmReservation",
      getReservation: "reservation/getItem",
      lookupReservationCode: "reservation/lookupReservationCode",
      activateLoading: "loading/activateLoading",
      deactivateLoading: "loading/deactivateLoading"
    })
  },
  async mounted() {
    this.activateLoading();
    if (this.$route.params.reservationId) {
      if (this.$route.params.reservationId.length === 6) {
        await this.lookupReservationCode(
          this.$route.params.reservationId.toUpperCase()
        );
        this.deactivateLoading();
      } else {
        await this.getReservation(this.$route.params.reservationId);

        this.deactivateLoading();
      }
    }
    this.deactivateLoading();
  },
  computed: {
    ...mapState({
      reservation: (state) => state.reservation.item
    })
  }
};
</script>

<style lang="scss" scoped></style>
