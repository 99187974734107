<template>
  <div>
    <section class="container mt-4">
      <div class="columns">
        <div class="column mx-5">
          <h4 class="is-size-4">Uh oh!</h4>
          <p>
            It looks like you canceled payment processing. You can always return
            and pay later or upon arrival.
          </p>

          <router-link
            class="button is-large is-size-4 is-fullwidth is-success mt-4"
            :to="{
              name: 'Payment',
              params: { reservationId: $route.params.reservationId }
            }"
          >
            Purchase
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
