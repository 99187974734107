<template>
  <div class="modal" :class="modalClassCollection">
    <div class="modal-background" :class="modalBackgroundClass"></div>
    <div class="modal-content" :class="modalContentClass">
      <component v-bind:is="modalContent"></component>
    </div>
    <button
      @click="handleModalClose"
      class="modal-close is-large"
      aria-label="close"
    ></button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
export default {
  computed: {
    ...mapGetters({
      modalStatus: "modal/modalStatus",
      modalContent: "modal/modalContent",
      modalClass: "modal/modalClass",
      modalContentClass: "modal/modalContentClass",
      modalBackgroundClass: "modal/modalBackgroundClass",
    }),
    modalClassCollection() {
      const isActive = this.modalStatus === true ? "is-active" : ""
      return `${this.modalClass} ${isActive}`
    },
  },
  methods: {
    ...mapActions({
      closeModal: "modal/closeModal",
    }),
    handleModalClose() {
      this.closeModal()
    },
  },
}
</script>

<style lang="scss" scoped></style>
