<template>
  <div class="is-size-5">
    <div class="mb-4">
      Let's double check your information.
    </div>
    <!-- <pre>{{ reservation }}</pre> -->
    <div class="mb-4" v-if="reservation.property && reservation.item">
      You are staying
      <strong
        >{{ reservation.property.houseNumber }}
        {{ reservation.property.streetName }}</strong
      >
      from
      <strong>{{
        reservation.item.dateArrivalUnix | moment("MMMM Do, YYYY")
      }}</strong>
      until
      <strong>{{
        reservation.item.dateDepartureUnix | moment("MMMM Do, YYYY")
      }}</strong
      >.
    </div>
    <div class="mb-4" v-if="reservation.reservation">
      You have
      <strong
        >{{ reservation.reservation.numberOfGuests }}
        {{
          reservation.reservation.numberOfGuests | pluralize("guest")
        }}</strong
      >
      with you and you'll have
      <strong
        >{{ reservation.reservation.numberOfVehicles }}
        {{
          reservation.reservation.numberOfVehicles | pluralize("vehicle")
        }}</strong
      >.
    </div>
    <div>
      Don't worry if something changes. We can always update your information
      after arrival or by contacting the Concierge team.
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      reservation: (state) => state.reservation
    })
  }
};
</script>

<style lang="scss" scoped></style>
