<template>
  <div>
    <p class="mb-3 is-size-5">
      If you are renting a vehicle or do not have the plate information, please
      skip that section. We will help you complete that upon your arrival at
      Emerald Island Resort.
    </p>
    <p class="mb-3 is-size-5">
      How many vehicles in your group?
    </p>
    <b-field>
      <b-numberinput
        min="0"
        max="6"
        v-model="form.numberOfVehicles"
        size="is-large"
        type="is-dark"
      ></b-numberinput>
    </b-field>
    <div v-if="form.numberOfVehicles > 0">
      <p class="mb-3 is-size-5">
        Plate Number(s)
      </p>
      <form @submit.prevent="addVehiclePlate">
        <b-field>
          <b-input
            size="is-medium"
            placeholder="Example: EIR-123"
            v-model="vehiclePlate"
            expanded
          ></b-input>
          <p class="control">
            <b-button
              class="button is-dark is-medium"
              @click="addVehiclePlate"
              label="Add Plate"
            />
          </p>
        </b-field>
      </form>
      <b-field v-if="form.vehicles.length > 0">
        <b-taginput
          v-model="form.vehicles"
          readonly
          type="is-dark"
          size="is-large"
        ></b-taginput>
      </b-field>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        numberOfVehicles: 0,
        vehicles: []
      },
      vehiclePlate: ""
    };
  },
  methods: {
    ...mapActions({
      updateReservation: "reservation/updateReservation"
    }),
    addVehiclePlate() {
      if (this.vehiclePlate !== "") {
        this.form.vehicles.push(this.vehiclePlate);
        this.vehiclePlate = "";
      }
    }
  },
  watch: {
    form: {
      handler: function() {
        this.debouncedSaveForm();
      },
      deep: true
    }
  },
  created: function() {
    this.debouncedSaveForm = _.debounce(() => {
      this.updateReservation({
        vehicles: this.form.vehicles,
        numberOfVehicles: this.form.numberOfVehicles
      });
    }, 500);
  }
};
</script>
