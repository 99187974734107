import Vue from "vue";
import Vuex from "vuex";

import { alert } from "./alert";
import { loading } from "./loading";
import { reservation } from "./reservation";
import { modal } from "./modal";
Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    alert,
    loading,
    reservation,
    modal
  }
});
