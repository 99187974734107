const state = {
  modalOpen: false,
  modalContent: false,
  modalClass: "",
  modalContentClass: "",
  modalBackgroundClass: ""
};

const mutations = {
  SET_MODAL_OPEN(state, payload) {
    state.modalOpen = true;
    (state.modalContent = payload.modalContent),
      (state.modalClass = payload.modalClass),
      (state.modalContentClass = payload.modalContentClass),
      (state.modalBackgroundClass = payload.modalBackgroundClass);
  },
  SET_MODAL_CLOSED(state) {
    state.modalContentClass = "";
    state.modalBackgroundClass = "";
    state.modalOpen = false;
    state.modalContent = null;
    state.modalClass = "";

    //  state.modalContentClass = "";
    //  state.modalBackgroundClass = "";
  }
};

const getters = {
  modalStatus(state) {
    return state.modalOpen;
  },
  modalClass(state) {
    return state.modalClass;
  },
  modalContentClass(state) {
    return state.modalContentClass;
  },
  modalBackgroundClass(state) {
    return state.modalBackgroundClass;
  },
  modalContent(state) {
    return state.modalContent;
  }
};

const actions = {
  openModal({ commit }, payload) {
    commit("SET_MODAL_OPEN", {
      modalContent: payload.modalContent,
      modalClass: payload.modalClass,
      modalContentClass: payload.modalContentClass,
      modalBackgroundClass: payload.modalBackgroundClass
    });
  },
  closeModal({ commit }) {
    commit("SET_MODAL_CLOSED");
  }
};

export const modal = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
