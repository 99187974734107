var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.reservation)?_c('div',{attrs:{"id":"guest-registration-page"}},[_c('section',{staticClass:"container has-text-centered"},[_c('h1',{staticClass:"is-size-4"},[_vm._v(" Thank you "+_vm._s(_vm.primaryGuest.nameFirst)+" "+_vm._s(_vm.primaryGuest.nameLast)+"! ")]),_c('h3',{staticClass:"is-size-5",staticStyle:{"margin-top":"1rem"}},[_vm._v(" We look forward to seeing you "+_vm._s(_vm._f("moment")(_vm.reservation.dateArrival,"MMMM Do, YYYY"))+" at Emerald Island Resort®! ")])]),_c('section',{staticClass:"container mt-4"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column mx-5"},[_c('p',{staticClass:"mb-4"},[_vm._v(" You will receive a link to the Welcome Booklet and your Access QR Code as soon as you have paid the Resort Access fee. The fee is $20 per card and we require a minimum of one (1) card per vehicle. ")]),_c('p',{staticClass:"mb-4"},[_vm._v(" A maximum of six (6) vehicles are allowed per property. You have "+_vm._s(_vm.reservation.guestInformation.numberOfVehicles)+" "+_vm._s(_vm._f("pluralize")(_vm.reservation.guestInformation.numberOfVehicles,"vehicle"))+" in your reservation. ")]),_c('p',[_vm._v(" You can purchase additional Access Cards as needed. ")])])])]),_c('section',{staticClass:"container mt-4"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column mx-5"},[_c('router-link',{staticClass:"button is-large is-size-4 is-success is-fullwidth",attrs:{"to":{
            name: 'Payment',
            params: {
              reservationId: _vm.reservation.id
            }
          }}},[_vm._v(" Purchase")])],1)])]),_c('section',{staticClass:"mt-5"},[_c('div',{staticClass:"container has-text-centered"},[_c('router-link',{staticClass:"is-size-5",attrs:{"to":{
          name: 'Reservation',
          params: {
            reservationId: _vm.reservation.id
          }
        }}},[_vm._v("skip")])],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }