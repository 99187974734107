import Vue from "vue";
import VueRouter from "vue-router";
// ** Public **
import GuestRegistration from "@/views/GuestRegistration.vue";
import GuestRegistrationConfirmation from "@/views/GuestRegistrationConfirmation.vue";
import PaymentSuccess from "@/views/PaymentSuccess";
import PaymentCancel from "@/views/PaymentCancel";
import Payment from "@/views/Payment";
import DisplayCode from "@/views/DisplayCode";

Vue.use(VueRouter);

const routes = [
  {
    path: "/registration/:reservationId?",
    alias: ["/register/:reservationId?", "/r/:reservationId?"],
    name: "guestRegistration",
    component: GuestRegistration,
    meta: {
      title: "Guest Registration"
    }
  },
  {
    path: "/registration/confirmation/:reservationId?",
    name: "GuestRegistrationConfirmation",
    component: GuestRegistrationConfirmation,
    meta: {
      title: "Registration Confirmation"
    }
  },
  {
    path: "/payment/success/:reservationId",
    name: "PaymentSuccess",
    component: PaymentSuccess,
    meta: {
      title: "Payment Success"
    }
  },
  {
    path: "/payment/:reservationId?",
    name: "Payment",
    component: Payment,
    meta: {
      title: "Payment"
    }
  },
  {
    path: "/payment/cancel/:reservationId",
    name: "PaymentCancel",
    component: PaymentCancel,
    meta: {
      title: "Payment Canceled"
    }
  },
  {
    path: "/reservation/:reservationId",
    alias: ["/res/:reservationId"],
    name: "Reservation",
    component: DisplayCode,
    meta: {
      title: "Reservation"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, _, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  if (nearestWithMeta) {
    nearestWithMeta.meta.metaTags
      .map((tagDef) => {
        const tag = document.createElement("meta");
        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute("data-vue-router-controlled", "");
        return tag;
      })
      .forEach((tag) => document.head.appendChild(tag));
  }

  next();
});

export default router;
