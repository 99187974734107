import { ToastProgrammatic as Toast } from "buefy";

const state = {
  message: null,
  type: null
};

const mutations = {
  clear(state) {
    state.message = null;
    state.type = null;
  },
  error(state, message) {
    state.message = message;
    state.type = "alert-danger";
  },
  success(state, message) {
    state.message = message;
    state.type = "alert-success";
  }
};

const actions = {
  clear({ commit }) {
    commit("clear");
    Toast.close();
  },
  error({ commit }, message) {
    commit("error", message);
    Toast.open({
      message: message,
      type: "is-danger"
    });
  },
  success({ commit }, message) {
    commit("success", message);
    Toast.open({
      message: message,
      type: "is-success"
    });
  }
};

export const alert = {
  namespaced: true,
  state,
  mutations,
  actions
};
