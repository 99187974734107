<template>
  <div>
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="(v) => (loading = v)"
    />
    <button @click="submit">Purchase!</button>
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";

export default {
  components: {
    StripeCheckout
  },
  data() {
    this.publishableKey =
      "pk_test_51Ho9P4HwMAfaUKeAA6VLYZcbob03dmX5DGOVrsn211dzxj2leerKhgi8JKNpPWrlR1lzD4zJfePdGiSf1UJsxPfY00Mafoal0d";
    return {
      loading: false,
      lineItems: [
        {
          price: "price_1JkxhkHwMAfaUKeA9SjwRnY3", // The id of the one-time price you created in your Stripe dashboard
          quantity: 6
        }
      ],
      successURL: "https://registration.kitomer.app/payment/success",
      cancelURL: "https://registration.kitomer.app/payment/cancel"
    };
  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    }
  }
};
</script>

<style lang="scss" scoped></style>
