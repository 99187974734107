<template>
  <div>
    <div>
      <p class="is-size-5  mb-3">
        Double check this info. If it is incorrect, please contact your host.
      </p>
      <b-field label="Address Where You Are Staying">
        <b-input disabled v-model="propertyAddress" size="is-large"></b-input>
      </b-field>
      <b-field label="Check-In Date">
        <b-datepicker
          placeholder="Select check-in date"
          icon="calendar-alt"
          trap-focus
          size="is-large"
          disabled
          v-model="dateArrival"
        ></b-datepicker>
      </b-field>
      <b-field label="Check-Out Date">
        <b-datepicker
          placeholder="Select check-out date"
          icon="calendar-alt"
          trap-focus
          disabled
          size="is-large"
          v-model="dateDeparture"
        ></b-datepicker>
      </b-field>
    </div>
    <div>
      <b-field label="First Name">
        <b-input
          size="is-large"
          v-model="nameFirst"
          disabled
          placeholder="Example: Jonny"
        ></b-input>
      </b-field>
      <b-field label="Last Name">
        <b-input
          size="is-large"
          disabled
          v-model="nameLast"
          placeholder="Example: Appleseed"
        ></b-input>
      </b-field>
      <b-field label="Phone">
        <b-input
          size="is-large"
          type="number"
          v-model="phone"
          required
          placeholder="Example: 321-555-1212"
          pattern="\d*"
        ></b-input>
      </b-field>

      <b-field label="Email">
        <b-input
          size="is-large"
          type="email"
          placeholder="Example: jonny@appleseed.com"
          v-model="email"
          required
        ></b-input>
      </b-field>
      <div class="field">
        <label class="label">How do you prefer to be notified?</label>
        <div class="control">
          <div class="select is-large is-fullwidth">
            <select name="" id="" v-model="notify">
              <option value="email">By Email</option>
              <option value="sms">By Text</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "ReservationInformationSection",
  methods: {
    ...mapActions({
      updatePrimaryGuest: "reservation/updatePrimaryGuest"
    })
  },
  computed: {
    ...mapState({
      property: (state) => state.reservation.property,
      primaryGuest: (state) => state.reservation.primaryGuest,
      reservation: (state) => state.reservation.item
    }),

    propertyAddress() {
      if (this.property) {
        return `${this.property.houseNumber} ${this.property.streetName}`;
      }
      return "";
    },
    dateArrival() {
      if (this.reservation) {
        return new Date(this.reservation.dateArrivalUnix * 1000);
      }
      return new Date();
    },
    dateDeparture() {
      if (this.reservation) {
        return new Date(this.reservation.dateDepartureUnix * 1000);
      }

      return new Date();
    },
    nameFirst() {
      if (this.primaryGuest) {
        return this.primaryGuest.nameFirst || "";
      }
      return "";
    },
    nameLast() {
      if (this.primaryGuest) {
        return this.primaryGuest.nameLast || "";
      }
      return "";
    },
    notify: {
      get: function() {
        if (this.primaryGuest) {
          return this.primaryGuest.notify;
        }
        return "no";
      },
      set: function(newValue) {
        this.updatePrimaryGuest({
          notify: newValue
        });
      }
    },
    phone: {
      get: function() {
        if (this.primaryGuest) {
          return this.primaryGuest.phone || "";
        }
        return "";
      },
      set: function(newValue) {
        this.updatePrimaryGuest({
          phone: newValue
        });
      }
    },
    email: {
      get: function() {
        if (this.primaryGuest) {
          return this.primaryGuest.email || "";
        }
        return "";
      },
      set: function(newValue) {
        this.updatePrimaryGuest({
          email: newValue
        });
      }
    }
  }
};
</script>
