<template>
  <div>
    <section class="container mt-4">
      <div class="columns">
        <div class="column mx-5">
          <h4 class="is-size-4 mb-3">Your Payment Has Been Recieved</h4>
          <p>
            You're all set! We have sent you a QR code that will allow you
            access to the Resort. Please give us 24 hours to process your
            physical access cards.
          </p>
          <div class="notification is-success is-size-5 py-2">
            <div class="columns is-mobile is-align-items-center	">
              <div class="column is-1"><i class="fad fa-clock fa-2x"></i></div>
              <div class="column">
                If you are arriving within the next 24 hours, please stop by the
                Clubhouse during normal business hours.
              </div>
            </div>
          </div>
          <p>
            If you encounter any issues, please contact our
            <a href="mailto:concierge@emeraldislandhoa.org">concierge team</a>.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 1em;
  font-size: 1.2em;
}
</style>
