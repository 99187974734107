<template>
  <div>
    <div class="is-size-4">
      <p class="mb-5">
        We're looking forward to your visit at Emerald Island Resort, a private
        community.
      </p>
      <p class="mb-5">
        In order to get your visit started with the least amount of delay, we
        have a few things we'd like to take care of before you get here.
      </p>
      <p>This should only take 3 minutes of your time.</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
