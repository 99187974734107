<template>
  <div id="guest-registration-page" v-if="reservation">
    <section class="container has-text-centered">
      <h1 class="is-size-4">
        Thank you
        {{ primaryGuest.nameFirst }} {{ primaryGuest.nameLast }}!
      </h1>
      <h3 class="is-size-5" style="margin-top:1rem;">
        We look forward to seeing you
        {{ reservation.dateArrival | moment("MMMM Do, YYYY") }} at Emerald
        Island Resort®!
      </h3>
    </section>
    <section class="container mt-4">
      <div class="columns">
        <div class="column mx-5">
          <p class="mb-4">
            You will receive a link to the Welcome Booklet and your Access QR
            Code as soon as you have paid the Resort Access fee. The fee is $20
            per card and we require a minimum of one (1) card per vehicle.
          </p>
          <p class="mb-4">
            A maximum of six (6) vehicles are allowed per property. You have
            {{ reservation.guestInformation.numberOfVehicles }}
            {{
              reservation.guestInformation.numberOfVehicles
                | pluralize("vehicle")
            }}
            in your reservation.
          </p>
          <p>
            You can purchase additional Access Cards as needed.
          </p>
        </div>
      </div>
    </section>
    <section class="container mt-4">
      <div class="columns">
        <div class="column mx-5">
          <router-link
            class="button is-large is-size-4 is-success is-fullwidth"
            :to="{
              name: 'Payment',
              params: {
                reservationId: reservation.id
              }
            }"
          >
            Purchase</router-link
          >
        </div>
      </div>
    </section>
    <section class="mt-5">
      <div class="container has-text-centered">
        <router-link
          class="is-size-5"
          :to="{
            name: 'Reservation',
            params: {
              reservationId: reservation.id
            }
          }"
          >skip</router-link
        >
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      reservation: (state) => state.reservation.item,
      primaryGuest: (state) => state.reservation.primaryGuest
    }),
    numberOfVehicles() {
      if (
        this.reservation &&
        this.reservation.guestInformation &&
        this.reservation.guestInformation.numberOfVehicles
      ) {
        return this.reservation.guestInformation.numberOfVehicles;
      }
      return 1;
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    ...mapActions({
      getReservation: "reservation/getItem",
      lookupReservationCode: "reservation/lookupReservationCode",
      activateLoading: "loading/activateLoading",
      deactivateLoading: "loading/deactivateLoading"
    })
  },
  async mounted() {
    this.activateLoading();
    if (this.$route.params.reservationId) {
      if (this.$route.params.reservationId.length === 6) {
        await this.lookupReservationCode(
          this.$route.params.reservationId.toUpperCase()
        );
      } else {
        await this.getReservation(this.$route.params.reservationId);
      }
    }
    this.deactivateLoading();
  }
};
</script>

<style lang="scss" scoped></style>
