<template>
  <div>
    <section class="container mt-4" v-if="!showCardInputs">
      <div class="columns">
        <div class="column mx-5">
          <p class="mb-4">
            Emerald Island Resort® requires a Resort Access fee. The fee is $20
            per card and we require a minimum of one (1) card per vehicle. A
            maximum of six (6) vehicles are allowed per property.
          </p>

          <form
            @submit.prevent="reservationCodeLookup"
            class="mb-4"
            v-if="!reservation"
          >
            <b-field>
              <b-input
                v-model="reservationCode"
                placeholder="ABCDEF"
                size="is-large"
                expanded
              ></b-input>
              <p class="control">
                <b-button
                  @click="reservationCodeLookup"
                  size="is-large"
                  type="is-dark"
                  label="Search"
                />
              </p>
            </b-field>
          </form>
          <p
            class="mb-4"
            v-if="
              reservation &&
              reservation.guestInformation &&
              numberOfVehicles > 0
            "
          >
            You have
            {{ numberOfVehicles }}
            {{ numberOfVehicles | pluralize("vehicle") }} in your reservation.
          </p>
          <p class="mb-4">
            You can purchase additional Access Cards as needed.
          </p>
          <b-field>
            <b-numberinput
              v-model="localCount"
              min="0"
              max="6"
              type="is-dark"
              size="is-large"
            ></b-numberinput>
          </b-field>
          <button
            class="button is-large is-size-4 is-fullwidth is-success"
            @click="submitPaymentIntent"
            :disabled="localCount === 0"
          >
            Checkout
          </button>
        </div>
      </div>
    </section>
    <section class="container mt-4" v-show="showCardInputs">
      <div class="columns">
        <div class="column mx-5">
          <p class="mb-4">
            Emerald Island Resort® requires a Resort Access fee. The fee is $20
            per card and we require a minimum of one (1) card per vehicle. A
            maximum of six (6) vehicles are allowed per property.
          </p>
          <div v-show="loadingCardInputs">
            <div class="columns">
              <div class="column is-half">
                <label class="cc-input-label" for="">Card number</label>
                <b-skeleton size="is-large" height="40px"></b-skeleton>
              </div>
              <div class="column">
                <label class="cc-input-label" for="">Expiry</label>
                <b-skeleton size="is-large" height="40px"></b-skeleton>
              </div>
              <div class="column">
                <label class="cc-input-label" for="">CVC</label>
                <b-skeleton size="is-large" height="40px"></b-skeleton>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <label class="cc-input-label" for="">Country</label>
                <b-skeleton size="is-large" height="40px"></b-skeleton>
              </div>
              <div class="column">
                <label class="cc-input-label" for="">Zip</label>
                <b-skeleton size="is-large" height="40px"></b-skeleton>
              </div>
            </div>
          </div>
          <div id="payment-element"></div>
          <button
            class="button is-large mt-5 is-size-4 is-fullwidth is-success"
            @click="handleSubmit"
          >
            Pay
          </button>
        </div>
      </div>
    </section>
    <section class="container mt-5 mx-4">
      <div class="columns">
        <div class="column has-text-centered">
          If you will not require Resort access during your stay, please click
          here.
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
// import { StripeCheckout } from "@vue-stripe/vue-stripe";
import * as stripe from "@stripe/stripe-js";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    // StripeCheckout
  },
  computed: {
    ...mapState({
      reservation: (state) => state.reservation.item
    }),
    numberOfVehicles() {
      if (
        this.reservation &&
        this.reservation.guestInformation &&
        this.reservation.guestInformation.numberOfVehicles
      ) {
        return this.reservation.guestInformation.numberOfVehicles;
      }
      return 0;
    },
    successURL() {
      let url = `${window.location.protocol}//${window.location.hostname}`;
      const reservationId = this.reservation?.id || "";

      if (window.location.port) {
        url += `:${window.location.port}`;
      }
      url += `/payment/success/${reservationId}`;
      return url;
    },
    cancelURL() {
      let url = `${window.location.protocol}//${window.location.hostname}`;
      const reservationId = this.reservation?.id || "";
      if (window.location.port) {
        url += `:${window.location.port}`;
      }
      url += `/payment/cancel/${reservationId}`;
      return url;
    }
  },
  data() {
    this.publishableKey =
      "pk_test_51Ho9P4HwMAfaUKeAA6VLYZcbob03dmX5DGOVrsn211dzxj2leerKhgi8JKNpPWrlR1lzD4zJfePdGiSf1UJsxPfY00Mafoal0d";
    return {
      showCardInputs: false,
      loadingCardInputs: false,
      stripe: null,
      elements: null,
      form: {},
      reservationCode: "",
      localCount: 1,
      loading: true
    };
  },
  methods: {
    ...mapActions({
      activateLoading: "loading/activateLoading",
      deactivateLoading: "loading/deactivateLoading",
      getReservation: "reservation/getItem",
      lookupReservationCode: "reservation/lookupReservationCode",
      error: "alert/error"
    }),
    async handleSubmit() {
      this.activateLoading();
      const { elements } = this;
      const { error } = await this.stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `https://registration.kitomer.app/payment/success/${this.$route.params.reservationId}`
        }
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        this.error(error.message);
      } else {
        this.error("An unexpected error occured.");
      }

      this.deactivateLoading();
    },
    async submitPaymentIntent() {
      this.activateLoading();
      let amount = this.localCount * 2000;
      console.log("amount", amount, typeof amount);
      const response = await axios({
        method: "POST",
        url: "https://us-central1-gateaccess-29471.cloudfunctions.net/stripeCheckout",
        headers: { "Content-Type": "application/json" },
        data: {
          amount: amount,
          metadata: {
            reservationId: this.reservation.id
          }
        }
      });

      const { clientSecret } = await response.data;

      const appearance = {
        theme: "stripe"
      };
      this.elements = this.stripe.elements({ appearance, clientSecret });

      const paymentElement = this.elements.create("payment");
      paymentElement.mount("#payment-element");
      this.showCardInputs = true;
      this.deactivateLoading();
    },
    async reservationCodeLookup() {
      await this.activateLoading();

      try {
        await this.lookupReservationCode(this.reservationCode);
        this.$router.replace({
          params: {
            reservationId: this.reservation.id
          }
        });
        this.deactivateLoading();
      } catch (error) {
        console.log("error", error);
        this.deactivateLoading();
      }
    }
  },
  async mounted() {
    this.activateLoading();
    this.loading = true;
    if (this.$route.params.reservationId) {
      if (this.$route.params.reservationId.length === 6) {
        await this.lookupReservationCode(
          this.$route.params.reservationId.toUpperCase()
        );
      } else {
        await this.getReservation(this.$route.params.reservationId);
      }
      this.stripe = await stripe.loadStripe(
        "pk_test_51Ho9P4HwMAfaUKeAA6VLYZcbob03dmX5DGOVrsn211dzxj2leerKhgi8JKNpPWrlR1lzD4zJfePdGiSf1UJsxPfY00Mafoal0d"
      );
      this.localCount = this.reservation.guestInformation.numberOfVehicles || 0;
    }
    this.deactivateLoading();
  }
};
</script>

<style lang="scss" scoped>
.cc-input-label {
  font-size: 0.9rem;
}
</style>
