<template>
  <div id="guest-registration-page">
    <div class="container" style="padding-bottom:100px" v-if="loaded">
      <div class="columns" v-if="!reservation">
        <div class="column mx-5">
          <div class="mb-3">
            <h4 class="is-size-3 mb-2">Uh oh!</h4>
            <p class="mb-3">
              It looks like we're missing your reservation information! Please
              enter your six character reservation code to continue.
            </p>
            <p class="mb-3">
              The reservation code should be in an email sent from Emerald
              Island Resort.
            </p>
            <p class="mb-3">
              If you still can not find your reservation code, please contact
              your host.
            </p>
          </div>
          <form @submit.prevent="reservationCodeLookup" class="mb-4">
            <b-field>
              <b-input
                v-model="reservationCode"
                placeholder="ABCDEF"
                size="is-large"
                expanded
              ></b-input>
              <p class="control">
                <b-button
                  @click="reservationCodeLookup"
                  size="is-large"
                  type="is-dark"
                  label="Search"
                />
              </p>
            </b-field>
          </form>
        </div>
      </div>

      <b-steps v-model="activeStep" v-if="reservation">
        <template v-for="(step, index) in steps">
          <b-step-item :key="index" :visible="step.visible" :label="step.label">
            <component
              v-if="step.component"
              v-bind:is="step.component"
            ></component>
            <span v-else>{{ step.content }}</span>
          </b-step-item>
        </template>

        <template #navigation="{previous, next}">
          <div
            class="columns is-mobile"
            style="position: fixed;bottom: 40px;width: 100%; margin: 0;"
          >
            <div class="column">
              <button
                class="button is-large is-fullwidth"
                :disabled="previous.disabled"
                @click.prevent="previous.action"
              >
                Previous
              </button>
            </div>
            <div class="column">
              <button
                v-show="!isLastStep"
                class="button is-large is-fullwidth"
                :disabled="next.disabled"
                @click.prevent="next.action"
              >
                Next
              </button>
              <button
                v-show="isLastStep"
                @click="submitRegistration"
                class="button is-success is-large is-fullwidth"
              >
                Submit
              </button>
            </div>
          </div>
        </template>
      </b-steps>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import welcomeSection from "./registration/Welcome";
import guestInformationSection from "./registration/GuestInformation";
import rulesSection from "./registration/Rules";
import vehicleInformationSection from "./registration/VehicleInformation";
import reservationInformationSection from "./registration/ReservationInformation";
import checkoutSection from "./registration/Checkout";
import registerSection from "./registration/Register";
export default {
  components: {
    welcomeSection,
    reservationInformationSection,
    vehicleInformationSection,
    rulesSection,
    guestInformationSection,
    checkoutSection,
    registerSection
  },

  computed: {
    ...mapState({
      reservation: (state) => state.reservation.item
    }),
    baseSteps() {
      return [
        {
          label: "Welcome",
          component: welcomeSection,
          visible: true
        },
        {
          label: "Reservation",
          component: reservationInformationSection,
          visible: true
        },
        {
          label: "Guests",
          component: guestInformationSection,
          visible: true
        },
        {
          label: "Vehicles",
          component: vehicleInformationSection,
          visible: true
        },
        {
          label: "Policies",
          component: rulesSection,
          visible: true
        },
        { label: "Register", component: registerSection, visible: true }
      ];
    },
    steps() {
      const steps = [...this.baseSteps];
      return steps;
    },
    isLastStep() {
      return this.activeStep === this.steps.length - 1;
    }
  },
  data() {
    return {
      loaded: false,
      activeStep: 0,
      reservationCode: ""
    };
  },
  methods: {
    ...mapActions({
      registerGuest: "guest/registerGuest",
      confirmReservation: "reservation/confirmReservation",
      getReservation: "reservation/getItem",
      lookupReservationCode: "reservation/lookupReservationCode",
      activateLoading: "loading/activateLoading",
      deactivateLoading: "loading/deactivateLoading"
    }),

    async submitRegistration() {
      await this.activateLoading();
      await this.confirmReservation();
      this.$router.push({
        name: "GuestRegistrationConfirmation",
        params: {
          reservationId: this.$route.params.reservationId
        }
      });
      this.deactivateLoading();
    },
    async reservationCodeLookup() {
      await this.activateLoading();

      try {
        await this.lookupReservationCode(this.reservationCode);
        this.$router.replace({
          params: {
            reservationId: this.$route.params.reservationId
          }
        });
        this.deactivateLoading();
      } catch (error) {
        console.log("error", error);
        this.deactivateLoading();
      }
    }
  },
  async mounted() {
    this.loaded = false;
    this.activateLoading();
    if (this.$route.params.reservationId) {
      if (this.$route.params.reservationId.length === 6) {
        await this.lookupReservationCode(
          this.$route.params.reservationId.toUpperCase()
        );
      } else {
        await this.getReservation(this.$route.params.reservationId);
      }
    }
    this.loaded = true;
    this.deactivateLoading();
  }
};
</script>

<style lang="scss" scoped>
.section-title {
  border-top: 1px solid #212121;
  border-bottom: 1px solid #212121;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
</style>
<style lang="scss">
#guest-registration-page {
  .taginput .taginput-container.is-focusable {
    margin-top: 1rem;
    border: 0;
    box-shadow: none;
  }
  .important-information li .b-checkbox.checkbox .control-label {
    padding-left: 1rem;
    font-size: 1rem;
  }
}
</style>
