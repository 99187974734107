<template>
  <div>
    <div class="mb-2 is-size-5">
      How many people total - including children - are in your group?
    </div>
    <b-field>
      <b-numberinput
        v-model="form.numberOfGuests"
        type="is-dark"
        size="is-large"
      ></b-numberinput>
    </b-field>
    <div v-if="form.numberOfGuests > 0">
      <div class="mb-2 is-size-5">
        Please include the first initial and last name of all adults - over 18 -
        staying with you.
      </div>
      <form @submit.prevent="addAdditionalGuestName">
        <b-field>
          <b-input
            v-model="additionalGuestName"
            placeholder="Example: A Dolphin"
            size="is-medium"
            expanded
          ></b-input>
          <p class="control">
            <b-button
              @click="addAdditionalGuestName"
              size="is-medium"
              type="is-dark"
              label="Add Guest"
            />
          </p>
        </b-field>
      </form>
      <div class="field" v-if="form.additionalGuests.length > 0">
        <b-taginput
          v-model="form.additionalGuests"
          readonly
          type="is-dark"
          size="is-large"
        ></b-taginput>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        numberOfGuests: 0,
        additionalGuests: []
      },
      additionalGuestName: ""
    };
  },
  methods: {
    ...mapActions({
      updateReservation: "reservation/updateReservation"
    }),
    addAdditionalGuestName() {
      if (this.additionalGuestName !== "") {
        this.form.additionalGuests.push(this.additionalGuestName);
        this.additionalGuestName = "";
      }
    }
  },
  watch: {
    form: {
      handler: function() {
        this.debouncedSaveForm();
      },
      deep: true
    }
  },
  created: function() {
    this.debouncedSaveForm = _.debounce(() => {
      this.updateReservation({
        additionalGuests: this.form.additionalGuests,
        numberOfGuests: this.form.numberOfGuests
      });
    }, 500);
  }
};
</script>
