<template>
  <div>
    <h2 class="is-size-5" style="margin-bottom:1rem;">
      PLEASE READ AND CHECK THE FOLLOWING TO VERIFY UNDERSTANDING
    </h2>
    <div>
      <ul class="important-information">
        <li>
          <b-checkbox size="is-large" v-model="form.checkboxNumberOfCars">
            A REASONABLE NUMBER OF CARS AND/OR OCCUPANTS PER HOME WILL BE
            PERMITTED, AS DETERMINED IN THE ASSOCIATION'S SOLE DISCRETION.
            <strong>MAXIMUM OF 6 VEHICLES AT ONE HOME</strong>
          </b-checkbox>
        </li>
        <li>
          <b-checkbox size="is-large" v-model="form.checkboxRightToPhotograph">
            THE ASSOCIATION HAS THE RIGHT AND AUTHORITY TO REQUIRE PRODUCTION OF
            A GOVERNMENT-­ISSUED DRIVER’S LICENSE OR SIMILAR DOCUMENT FOR
            IDENTIFICATION PURPOSES; TO PHOTOGRAPH SUCH LICENSE/DOCUMENT AS WELL
            AS THE LICENSE PLATE OF ANY VEHICLE ENTERING THE PROPERTY; AND TO
            RETAIN SUCH PHOTOGRAPH(S) DURING A VISIT TO THE PROPERTY AND FOR ONE
            MONTH FOLLOWING SUCH VISIT. ANY PERSON THAT FAILS OR REFUSES TO
            PRODUCE A REQUESTED GOVERNMENT-­ISSUED DRIVER’S LICENSE/DOCUMENT THE
            ASSOCIATION TO TAKE SUCH PHOTOGRAPHS SHALL BE DENIED ENTRY TO THE
            PROPERTY
          </b-checkbox>
        </li>
        <li>
          <b-checkbox size="is-large" v-model="form.checkboxHomesParkingSide">
            <p>HOMES STREET PARKING:</p>

            <p>
              <strong>EVEN NUMBERED MONTHS</strong> - PARK ON THE EVEN SIDE OF
              THE STREET
            </p>

            <p>
              <strong>ODD NUMBERED MONTHS</strong> - PARK ON THE ODD SIDE OF THE
              STREET
            </p>
          </b-checkbox>
        </li>
        <li>
          <b-checkbox
            size="is-large"
            v-model="form.checkboxHomesParkingSidewalks"
          >
            BE CONSIDERATE WITH PARKING YOUR VEHICLE AT POOL HOMES AND LEAVE
            ROOM FOR PEOPLE TO USE THE SIDEWALKS WHEN EVER POSSIBLE
          </b-checkbox>
        </li>
        <li>
          <b-checkbox size="is-large" v-model="form.checkboxHomesParkingGrass">
            <strong>NO PARKING IS ALLOWED ON ANY PARTS OF THE GRASS</strong>
          </b-checkbox>
        </li>
        <li>
          <b-checkbox size="is-large" v-model="form.checkboxHomesTrash">
            POOL HOMES TRASH IS PICKED UP ON THURSDAYS AROUND 6AM. RECYCLING
            (TAN BIN WITH BLUE TOP) IS PICKED UP ON WEDNESDAY MORNINGS. PLEASE
            DO NOT PUT FOOD IN THIS CONTAINER. EIR STAFF WILL TAKE TRASH CANS TO
            THE CURB THE DAY BEFORE AND RETURN THE DAY OF THE PICKUP. ALL
            TRASH/RECYCLING NOT FITTING INTO TRASH CANS MUST BE TAKEN TO
            COMPACTOR
          </b-checkbox>
        </li>
        <li>
          <b-checkbox size="is-large" v-model="form.checkboxTownhomesTrash">
            TOWN HOMES USE AN IN-GOUND TRASH SYSTEM UTILIZING A 13 GALLON TRASH
            BAG ONLY AND HAVE VALET TRASH MONDAY THROUGH SATURDAY
          </b-checkbox>
        </li>
        <li>
          <b-checkbox size="is-large" v-model="form.checkboxPets">
            PETS ARE PERMITTED WITH THE OWNER OF THE HOMES PERMISSION. ALL PETS
            MUST BE ON A LEASH AT ALL TIMES WHILE OUTSIDE THE HOME, AND YOU MUST
            PICK UP AFTER YOUR PET(S)
          </b-checkbox>
        </li>
        <li>
          <b-checkbox size="is-large" v-model="form.checkboxQuietHours"
            >QUIET HOURS ARE FROM 11PM TO 8AM</b-checkbox
          >
        </li>
        <li>
          <b-checkbox size="is-large" v-model="form.checkboxPackages">
            ALL GUESTS MUST HAVE ALL PACKAGES DELIVERED BY FED EX OR UPS TO
            THEIR ADDRESS-­‐USPS DOES NOT DELIVER HERE TO EMERALD ISLAND
          </b-checkbox>
        </li>
        <li>
          <b-checkbox size="is-large" v-model="form.checkboxResponsibleParty">
            I UNDERSTAND THAT I AM RESPONSIBLE FOR ALL PERSONS STAYING WITH ME
          </b-checkbox>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        //checkboxes
        checkboxNumberOfCars: true,
        checkboxRightToPhotograph: true,
        checkboxHomesParkingSide: true,
        checkboxHomesParkingSidewalks: true,
        checkboxHomesParkingGrass: true,
        checkboxHomesTrash: true,
        checkboxTownhomesTrash: true,
        checkboxPets: true,
        checkboxQuietHours: true,
        checkboxPackages: true,
        checkboxResponsibleParty: true
      }
    };
  },
  methods: {
    ...mapActions({
      updateReservation: "reservation/updateReservation"
    })
  },
  watch: {
    form: {
      handler: function() {
        this.debouncedSaveForm();
      },
      deep: true
    }
  },
  created: function() {
    this.updateReservation({
      policies: this.form
    });
    this.debouncedSaveForm = _.debounce(() => {
      this.updateReservation({
        policies: this.form
      });
    }, 500);
  }
};
</script>

<style lang="scss" scoped>
.important-information li {
  margin: 3rem 0;
  // border-top: 1px solid #212121;
  // border-bottom: 1px solid #212121;
}
</style>
<style lang="scss">
.important-information li .b-checkbox.checkbox .control-label {
  padding-left: 1rem;
  font-size: 1rem;
}
</style>
