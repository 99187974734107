import { config } from "../config";
import axios from "axios";
// import Parse from "parse";
// import emails from "../emails";
import QRCode from "qrcode";
// import moment from "moment";

const state = {
  item: null,
  property: null,
  primaryGuest: null,
  reservation: null
};

const mutations = {
  SET_RESERVATION(state, payload) {
    if (payload.reservation) {
      state.item = payload.reservation;
    }
    if (payload.property) {
      state.property = {
        id: payload.property.id,
        ...payload.property.data
      };
    }
    if (payload.primaryGuest) {
      state.primaryGuest = {
        id: payload.primaryGuest.id,
        ...payload.primaryGuest.data
      };
    }
  },
  UPDATE_RESERVATION(state, update) {
    state.reservation = { ...state.reservation, ...update };
  },
  UPDATE_PRIMARY_GUEST(state, update) {
    console.log("show", update);
    state.primaryGuest = Object.assign(state.primaryGuest, update);
  }
};

const actions = {
  async lookupReservationCode({ commit }, code) {
    try {
      const reservation = await axios.get(
        `${config.api}reservationCodeLookup`,
        {
          params: { code: code }
        }
      );

      if (reservation && reservation.status === 200) {
        commit("SET_RESERVATION", { ...reservation.data });
        return Promise.resolve(reservation.data);
      } else {
        console.log("Error getting reservation: ", reservation.statusText);
        return Promise.reject();
      }
    } catch (error) {
      console.log("error", error);
      return Promise.reject();
    }
  },
  async confirmReservation({ state }) {
    try {
      const objToPost = {
        reservationId: state.item.id,
        guestInformation: state.reservation,
        primaryGuestId: state.primaryGuest.id,
        primaryGuestInformation: state.primaryGuest
      };
      console.log("object akljsdfs", objToPost);
      await axios.post(`${config.api}guestReservationUpdates`, objToPost);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateReservation({ commit }, update) {
    commit("UPDATE_RESERVATION", update);
    return Promise.resolve();
  },
  async updatePrimaryGuest({ commit }, update) {
    console.log("oupasdf", update);
    try {
      commit("UPDATE_PRIMARY_GUEST", update);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getItem({ commit }, reservationId) {
    try {
      // Get Reservation
      const reservation = await axios.get(`${config.api}reservation`, {
        params: { reservationId: reservationId }
      });
      if (reservation && reservation.status === 200) {
        commit("SET_RESERVATION", reservation.data);
        return Promise.resolve();
      } else {
        console.log("Error getting reservation: ", reservation.statusText);
        return Promise.reject();
      }
    } catch (error) {
      console.log("error", error);
      return Promise.reject();
    }
  },
  generateQR(_, code) {
    return new Promise((resolve) => {
      QRCode.toDataURL(code).then((result) => {
        resolve(result);
      });
    });
  }
};

export const reservation = {
  namespaced: true,
  actions,
  mutations,
  state
};
